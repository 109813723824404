<template>
  <c-box
    flex-grow="1"
    min-width="0"
  >
    <BreadcrumbPath
      margin-bottom="20px"
      :paths="[
        {
          label: 'Manajemen Klien',
          href: '/admin/clients',
          isCurrent: true,
        },
      ]"
    />
    <c-box
      width="100%"
      background-color="#FFF"
      margin-bottom="16px"
      :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      :border-radius="['0px', '16px']"
      :padding="['16px', '30px']"
      :min-height="['unset', '74vh']"
      display="flex"
      flex-direction="column"
      align-items="center"
    >
      <c-flex margin-bottom="16px">
        <BaseText
          size-mobile="20px"
          size-desktop="28px"
        >
          Manajemen Klien
        </BaseText>

        <!-- <c-button
          v-if="role !== 'super_admin'"
          h="auto"
          py="12px"
          px="28px"
          color="#927102"
          border-radius="24px"
          border-color="transparent"
          bg="secondary.800"
          variant-color="inherit"
          font-size="16px"
          line-height="24px"
          variant="solid"
          @click="$emit('add-client')"
        >
          <c-image
            :src="require('@/assets/icon-plus.svg')"
            alt="icon"
            display="inline-block"
            mr="10px"
          />
          Tambah Klien
        </c-button> -->
      </c-flex>

      <c-box
        width="100%"
        display="flex"
        flex-direction="column"
        gap="18px"
        margin-top="6px"
        background-color="secondary.50"
      >
        <c-box
          display="flex"
          justify-content="space-between"
          align-items="center"
          background-color="warning.200"
          min-height="70px"
          padding="16px"
          border-top-left-radius="8px"
          border-top-right-radius="8px"
        >
          <BaseText
            size-mobile="16px"
            size-desktop="18px"
          >
            Daftar Panduan Makan
          </BaseText>
          <c-flex gap="16px">
            <c-box width="100%">
              <c-form-control
                pos="relative"
                w="100%"
                height="100%"
              >
                <c-image
                  :src="require('@/assets/ic-search.svg')"
                  alt="icon"
                  position="absolute"
                  top="50%"
                  transform="translateY(-50%)"
                  left="15px"
                  height="24px"
                  z-index="2"
                />
                <c-input
                  id="search"
                  v-model="filterSearchMealPlan"
                  type="text"
                  w="100%"
                  height="100%"
                  placeholder="Search"
                  border-radius="20px"
                  padding-left="50px"
                  position="relative"
                  z-index="1"
                  border="1px solid #C4C4C4"
                  @keyup="onSearchMealPlan"
                />
              </c-form-control>
            </c-box>

            <BaseMenu trigger="manual">
              <template #button="{ toggle }">
                <c-button
                  variant-color="gray"
                  variant="outline"
                  color="neutral.black"
                  background-color="neutral.superLightGray"
                  font-size="16px"
                  font-weight="400"
                  min-height="50px"
                  border-radius="20px"
                  position="relative"
                  @click="toggle"
                >
                  Filter
                  <c-box
                    margin-left="16px"
                    position="relative"
                  >
                    <inline-svg
                      :src="require('@/assets/icons/icon-filter.svg')"
                      height="13"
                      width="13"
                      fill="#555555"
                    />
                    <c-box
                      v-if="mealPlans.meta.filter"
                      position="absolute"
                      :width="['3px','8px']"
                      :height="['3px','8px']"
                      border-radius="8px"
                      top="-7px"
                      right="-7px"
                      background-color="#FF0000"
                      class="animate-pulse"
                    />
                  </c-box>
                </c-button>
              </template>
              <template #menu="{ close }">
                <c-box
                  z-index="10"
                  min-width="300px"
                  px="24px"
                  padding-bottom="12px"
                  mt="24px"
                >
                  <c-box text-align="start">
                    <BaseInputSelect
                      v-model="filterStatusMealPlan"
                      placeholder="Pilih Status"
                      label="Status"
                      size="sm"
                      :options="[
                        { value: 'review', label: 'Menunggu Review' },
                        { value: 'revisi', label: 'Sedang Direvisi' },
                        { value: 'sent', label: 'Diterima Klien' },
                        { value: 'read', label: 'Sudah dibuka Klien' },
                      ]"
                    />
                  </c-box>
                  <c-menu-divider />
                  <c-flex
                    justify="flex-end"
                    padding="0 8px"
                  >
                    <BaseButton
                      border-radius="1000px"
                      size="small"
                      padding="0 24px"
                      @click="
                        () => {
                          close()
                          applyFilterMealPlans()
                        }
                      "
                    >
                      Simpan
                    </BaseButton>
                  </c-flex>
                </c-box>
              </template>
            </BaseMenu>
          </c-flex>
        </c-box>
        <BaseTable
          color="secondary"
          :columns="mealPlans.columns"
          :items="mealPlans.items"
          :page="mealPlans.meta?.page"
          :per-page="mealPlans.meta?.pagesize"
          :total-page="calculatePerPage(mealPlans?.total, mealPlans.meta?.pagesize)"
          :sort="mealPlans.meta?.sort"
          :is-loading="isLoadingMealPlans"
          @on-change-page="(page_) => onChangePage('MEAL_PLANS', page_)"
        >
          <!--        @on-change-sort="(sort) => onChangeSort('MEAL_PLANS', sort)"-->
          <template #status="{ item }">
            <ChipStatus2
              :style="{ width: '170px' }"
              :status="item?.status"
              screen="mobile-only"
            />
          </template>
          <template #aksi="{ item }">
            <c-button
              background-color="white"
              border-radius="16px"
              padding="8px 16px"
              font-size="14px"
              border="2px solid #008C81"
              font-weight="medium"
              color="primary.400"
              @click="toDetailPage(item)"
            >
              Lihat
            </c-button>
          </template>
        </BaseTable>
      </c-box>

      <!-- List Clients -->
      <c-box
        width="100%"
        display="flex"
        flex-direction="column"
        gap="12px"
        margin-top="30px"
      >
        <c-flex
          justify-content="space-between"
          align-items="center"
          background-color="neutral.white"
          gap="16px"
        >
          <c-box
            flex-grow="1"
            width="100%"
          >
            <c-form-control
              pos="relative"
              w="100%"
              height="100%"
            >
              <c-image
                :src="require('@/assets/ic-search.svg')"
                alt="icon"
                position="absolute"
                top="50%"
                transform="translateY(-50%)"
                left="15px"
                height="24px"
                z-index="2"
              />
              <c-input
                id="search"
                v-model="filterSearchClient"
                type="text"
                w="100%"
                height="100%"
                min-height="50px"
                placeholder="Search"
                border-radius="16px"
                padding-left="50px"
                position="relative"
                z-index="1"
                border="1px solid #C4C4C4"
              />
            </c-form-control>
          </c-box>
          <c-flex
            flex-shrink="0"
            gap="16px"
          >
            <c-flex align="center">
              <BaseText
                size-mobile="14px-2"
                size-desktop="16px"
              >
                Show
              </BaseText>
              <c-select
                min-width="140px"
                border-radius="8px"
                size="md"
                color="primary.400"
                font-size="18px"
                font-weight="500"
                margin-left="16px"
                :value="String(clients?.meta?.pagesize)"
                @change="(perPage) => onChangePerPage('CLIENTS', +perPage)"
              >
                <option value="5">
                  5 Rows
                </option>
                <option value="10">
                  10 Rows
                </option>
                <option value="30">
                  30 Rows
                </option>
                <option value="50">
                  50 Rows
                </option>
              </c-select>
            </c-flex>
            
            <ManagementClientFilterBtn 
              :filters="filters"
              @change="(status) => onChangeStatus('CLIENTS', status)"
            />
          </c-flex>
        </c-flex>

        <BaseTable
          color="primary"
          :columns="clients.columns"
          :items="clients.items"
          :page="clients.meta?.page"
          :per-page="clients.meta?.pagesize"
          :total-page="calculatePerPage(clients?.total, clients.meta?.pagesize)"
          :sort="clients.meta?.sort"
          :is-loading="isLoading"
          @on-change-page="(page_) => onChangePage('CLIENTS', page_)"
          @on-change-sort="(sort) => onChangeSort('CLIENTS', sort)"
        >
          <template #program="{ item }">
            <c-box
              v-if="item?.programs?.name"
            >
              {{ item?.programs?.name }} -<br>
              {{ item?.programs?.programService }}
            </c-box>
            <c-box
              v-else
            >
              -
            </c-box>
          </template>
          <template #status="{ item }">
            <ChipStatusProgram
              v-if="item?.status"
              :status="item?.status"
              screen="mobile-only"
            />
            <c-box
              v-else
            >
              -
            </c-box>
          </template>
          <template #aksi="{ item }">
            <c-button
              background-color="white"
              border-radius="16px"
              padding="8px 16px"
              font-size="14px"
              border="2px solid #008C81"
              font-weight="medium"
              color="primary.400"
              @click="toDetailClient(item)"
            >
              Lihat
            </c-button>
          </template>
        </BaseTable>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import ManagementClientFilterBtn from '@/components/managements/clients/filter-button'
import BaseTable from '@/components/elements/base-table.vue'
import BaseMenu from '@/components/elements/base-menu.vue'
import BaseInputSelect from '@/components/elements/base-input-select.vue'
import BaseText from '@/components/elements/base-text.vue'
import BaseButton from '@/components/elements/base-button.vue'
import { formatDateV2 } from '@/utils/format-date'
import _ from 'lodash'
import { mapActions } from 'vuex'
import ChipStatus2 from '@/components/elements/chip-status-2.vue'
import ChipStatusProgram from '@/components/elements/chip-status-program.vue'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'

export default {
  name: 'ManagementClientsIndex',
  components: {
    ChipStatus2,
    BaseButton,
    BaseText,
    BaseInputSelect,
    BaseMenu,
    BaseTable,
    BreadcrumbPath,
    ManagementClientFilterBtn,
    ChipStatusProgram,
  },
  props: ['filters', 'clients', 'exportUrl', 'role', 'isLoading'],
  emits: ['update:persize', 'update:sort'],
  data() {
    return {
      filterSearchClient: this.$route.query.q,
      filterStatusMealPlan: '',
      filterSearchMealPlan: '',
      timerFilterSearchMealPlan: null,
      mealPlans: {
        columns: [
          {
            id: 'client.fullName',
            label: 'Nama Klien',
            type: 'TEXT',
            customRender: (item) => {
              return item?.clientName || '-'
            },
          },
          {
            id: 'clientManager.fullName',
            label: 'Ahli Gizi',
            type: 'TEXT',
            customRender: (item) => {
              return item?.nutritionistName || '-'
            },
          },
          {
            id: 'createdAt',
            label: 'Dikirim pada',
            type: 'TEXT',
            customRender: (item) => {
              return item?.createdAt ? formatDateV2(item?.createdAt, 8) : '-'
            },
          },
          {
            id: 'status',
            label: 'Status',
            type: 'CUSTOM2',
          },
          {
            id: 'aksi',
            label: 'Aksi',
            type: 'CUSTOM2',
          },
        ],
        items: [],
        meta: {
          page: 1,
          pagesize: 5,
          filter: '',
        },
        total: 0,
      },
      isLoadingMealPlans: false,
    }
  },
  computed: {
    page() {
      return +(this.$route.query.page ?? 1)
    },
    rows() {
      return this.$route.query.rows ?? '10'
    },
    status: {
      get() {
        return this.$route.query.status
      },
      set(v) {
        if (this.$route.query.status === v) return
        this.$router.replace({ query: { ...this.$route.query, status: v } })
      },
    },
  },
  watch: {
    'mealPlans.meta': {
      handler: _.debounce(async function(newVal) {
        this.isLoadingMealPlans = true
        const res = await this.loadMealPlans({
          page: newVal.page,
          perpage: newVal.pagesize,
          q: this.filterSearchMealPlan,
          filter: this.filterStatusMealPlan,
        })
        this.mealPlans.items = res.data?.result
        this.mealPlans.total = res.meta.total
        this.mealPlans.meta.filter = this.filterStatusMealPlan
        this.isLoadingMealPlans = false
      }, 0),
      deep: true,
      immediate: true,
    },
    filterSearchClient: {
      handler: _.debounce(async function(val) {
        this.$router.replace({ 
          query: { 
            ...this.$route.query,
            page: 1,
            q: val,
          },
          params: { savePosition: true },
        })
      }, 1000),
    },
  },
  methods: {
    ...mapActions({
      loadMealPlans: 'admDashboard/listMealPlans',
    }),
    onSearchMealPlan() {
      if (this.timerFilterSearchMealPlan) {
        clearTimeout(this.timerFilterSearchMealPlan)
        this.timerFilterSearchMealPlan = null
      }
      this.timerFilterSearchMealPlan = setTimeout(() => {
        this.applyFilterMealPlans()
      }, 1000)
    },
    async applyFilterMealPlans() {
      this.$router.replace({
        name: 'admin.clients',
        query: {
          ...this.$route.query,
          meal_plans_q: this.filterSearchMealPlan,
          meal_plans_status: this.filterStatusMealPlan,
        },
        params: { savePosition: true },
      })
      this.mealPlans.meta.page = 1
      this.isLoadingMealPlans = true
      const res = await this.loadMealPlans({
        page: this.mealPlans.meta.page,
        perpage: this.mealPlans.meta.pagesize,
        q: this.filterSearchMealPlan,
        filter: this.filterStatusMealPlan,
      })
      this.mealPlans.items = res.data?.result
      this.mealPlans.total = res.meta.total
      this.mealPlans.meta.filter = this.filterStatusMealPlan
      this.isLoadingMealPlans = false
    },
    onChangePerPage(type, perPage) {
      switch (type) {
        case 'CLIENTS':
          this.$emit('update:persize', perPage)
          this.$router.replace({
            query: {
              ...this.$route.query,
              page: 1,
              rows: perPage,
            },
            params: { savePosition: true },
          })
          break
        case 'MEAL_PLANS':
          this.mealPlans.meta.pagesize = perPage
          this.mealPlans.meta.page = 1
          break
        default:
          break
      }
    },
    onChangePage(type, page) {
      switch (type) {
        case 'CLIENTS':
          this.$router.replace({
            query: {
              ...this.$route.query,
              page: page,
            },
            params: { savePosition: true },
          })
          break
        case 'MEAL_PLANS':
          this.mealPlans.meta.page = page
          break
        default:
          break
      }
    },
    onChangeStatus(type, status) {
      switch (type) {
        case 'CLIENTS':
          this.$router.replace({
            query: {
              ...this.$route.query,
              page: 1,
              status: status === 'all' ? '' : status,
            },
            params: { savePosition: true },
          })
          break
        default:
          break
      }
    },
    onChangeSort(type, sort) {
      const order = Object.values(sort)[0]?.toLowerCase()
      let field = Object.keys(sort)[0]
      const sorting = {
        [field]: order,
      }

      switch (type) {
        case 'CLIENTS':
          this.$emit('update:sort', sorting)
          this.$router.replace({
            query: {
              ...this.$route.query,
              orderBy: field,
              order: order,
            },
            params: { savePosition: true },
          }).catch(() => {})
          break
        default:
          break
      }
    },
    calculatePerPage(totalData, perPage) {
      return Math.ceil(totalData / perPage)
    },
    toDetailPage(item) {
      this.$router.push({
        name: 'admin.clients.meal-plan.profile',
        params: {
          clientId: item?.clientId,
          programId: item?.programId,
          month: item?.month,
        },
      })
    },
    toDetailClient(item) {
      this.$router.push({
        name: 'admin.clients-detail',
        params: { clientId: item?.id },
      })
    },
  },
}
</script>
