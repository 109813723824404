var render = function () {
  var _vm$mealPlans$meta, _vm$mealPlans$meta2, _vm$mealPlans, _vm$mealPlans$meta3, _vm$mealPlans$meta4, _vm$clients, _vm$clients$meta, _vm$clients$meta2, _vm$clients$meta3, _vm$clients2, _vm$clients$meta4, _vm$clients$meta5;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "flex-grow": "1",
      "min-width": "0"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "margin-bottom": "20px",
      "paths": [{
        label: 'Manajemen Klien',
        href: '/admin/clients',
        isCurrent: true
      }]
    }
  }), _c('c-box', {
    attrs: {
      "width": "100%",
      "background-color": "#FFF",
      "margin-bottom": "16px",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "padding": ['16px', '30px'],
      "min-height": ['unset', '74vh'],
      "display": "flex",
      "flex-direction": "column",
      "align-items": "center"
    }
  }, [_c('c-flex', {
    attrs: {
      "margin-bottom": "16px"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "20px",
      "size-desktop": "28px"
    }
  }, [_vm._v(" Manajemen Klien ")])], 1), _c('c-box', {
    attrs: {
      "width": "100%",
      "display": "flex",
      "flex-direction": "column",
      "gap": "18px",
      "margin-top": "6px",
      "background-color": "secondary.50"
    }
  }, [_c('c-box', {
    attrs: {
      "display": "flex",
      "justify-content": "space-between",
      "align-items": "center",
      "background-color": "warning.200",
      "min-height": "70px",
      "padding": "16px",
      "border-top-left-radius": "8px",
      "border-top-right-radius": "8px"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "16px",
      "size-desktop": "18px"
    }
  }, [_vm._v(" Daftar Panduan Makan ")]), _c('c-flex', {
    attrs: {
      "gap": "16px"
    }
  }, [_c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_c('c-form-control', {
    attrs: {
      "pos": "relative",
      "w": "100%",
      "height": "100%"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic-search.svg'),
      "alt": "icon",
      "position": "absolute",
      "top": "50%",
      "transform": "translateY(-50%)",
      "left": "15px",
      "height": "24px",
      "z-index": "2"
    }
  }), _c('c-input', {
    attrs: {
      "id": "search",
      "type": "text",
      "w": "100%",
      "height": "100%",
      "placeholder": "Search",
      "border-radius": "20px",
      "padding-left": "50px",
      "position": "relative",
      "z-index": "1",
      "border": "1px solid #C4C4C4"
    },
    on: {
      "keyup": _vm.onSearchMealPlan
    },
    model: {
      value: _vm.filterSearchMealPlan,
      callback: function callback($$v) {
        _vm.filterSearchMealPlan = $$v;
      },
      expression: "filterSearchMealPlan"
    }
  })], 1)], 1), _c('BaseMenu', {
    attrs: {
      "trigger": "manual"
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn(_ref) {
        var toggle = _ref.toggle;
        return [_c('c-button', {
          attrs: {
            "variant-color": "gray",
            "variant": "outline",
            "color": "neutral.black",
            "background-color": "neutral.superLightGray",
            "font-size": "16px",
            "font-weight": "400",
            "min-height": "50px",
            "border-radius": "20px",
            "position": "relative"
          },
          on: {
            "click": toggle
          }
        }, [_vm._v(" Filter "), _c('c-box', {
          attrs: {
            "margin-left": "16px",
            "position": "relative"
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": require('@/assets/icons/icon-filter.svg'),
            "height": "13",
            "width": "13",
            "fill": "#555555"
          }
        }), _vm.mealPlans.meta.filter ? _c('c-box', {
          staticClass: "animate-pulse",
          attrs: {
            "position": "absolute",
            "width": ['3px', '8px'],
            "height": ['3px', '8px'],
            "border-radius": "8px",
            "top": "-7px",
            "right": "-7px",
            "background-color": "#FF0000"
          }
        }) : _vm._e()], 1)], 1)];
      }
    }, {
      key: "menu",
      fn: function fn(_ref2) {
        var close = _ref2.close;
        return [_c('c-box', {
          attrs: {
            "z-index": "10",
            "min-width": "300px",
            "px": "24px",
            "padding-bottom": "12px",
            "mt": "24px"
          }
        }, [_c('c-box', {
          attrs: {
            "text-align": "start"
          }
        }, [_c('BaseInputSelect', {
          attrs: {
            "placeholder": "Pilih Status",
            "label": "Status",
            "size": "sm",
            "options": [{
              value: 'review',
              label: 'Menunggu Review'
            }, {
              value: 'revisi',
              label: 'Sedang Direvisi'
            }, {
              value: 'sent',
              label: 'Diterima Klien'
            }, {
              value: 'read',
              label: 'Sudah dibuka Klien'
            }]
          },
          model: {
            value: _vm.filterStatusMealPlan,
            callback: function callback($$v) {
              _vm.filterStatusMealPlan = $$v;
            },
            expression: "filterStatusMealPlan"
          }
        })], 1), _c('c-menu-divider'), _c('c-flex', {
          attrs: {
            "justify": "flex-end",
            "padding": "0 8px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "border-radius": "1000px",
            "size": "small",
            "padding": "0 24px"
          },
          on: {
            "click": function click() {
              close();

              _vm.applyFilterMealPlans();
            }
          }
        }, [_vm._v(" Simpan ")])], 1)], 1)];
      }
    }])
  })], 1)], 1), _c('BaseTable', {
    attrs: {
      "color": "secondary",
      "columns": _vm.mealPlans.columns,
      "items": _vm.mealPlans.items,
      "page": (_vm$mealPlans$meta = _vm.mealPlans.meta) === null || _vm$mealPlans$meta === void 0 ? void 0 : _vm$mealPlans$meta.page,
      "per-page": (_vm$mealPlans$meta2 = _vm.mealPlans.meta) === null || _vm$mealPlans$meta2 === void 0 ? void 0 : _vm$mealPlans$meta2.pagesize,
      "total-page": _vm.calculatePerPage((_vm$mealPlans = _vm.mealPlans) === null || _vm$mealPlans === void 0 ? void 0 : _vm$mealPlans.total, (_vm$mealPlans$meta3 = _vm.mealPlans.meta) === null || _vm$mealPlans$meta3 === void 0 ? void 0 : _vm$mealPlans$meta3.pagesize),
      "sort": (_vm$mealPlans$meta4 = _vm.mealPlans.meta) === null || _vm$mealPlans$meta4 === void 0 ? void 0 : _vm$mealPlans$meta4.sort,
      "is-loading": _vm.isLoadingMealPlans
    },
    on: {
      "on-change-page": function onChangePage(page_) {
        return _vm.onChangePage('MEAL_PLANS', page_);
      }
    },
    scopedSlots: _vm._u([{
      key: "status",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('ChipStatus2', {
          style: {
            width: '170px'
          },
          attrs: {
            "status": item === null || item === void 0 ? void 0 : item.status,
            "screen": "mobile-only"
          }
        })];
      }
    }, {
      key: "aksi",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('c-button', {
          attrs: {
            "background-color": "white",
            "border-radius": "16px",
            "padding": "8px 16px",
            "font-size": "14px",
            "border": "2px solid #008C81",
            "font-weight": "medium",
            "color": "primary.400"
          },
          on: {
            "click": function click($event) {
              return _vm.toDetailPage(item);
            }
          }
        }, [_vm._v(" Lihat ")])];
      }
    }])
  })], 1), _c('c-box', {
    attrs: {
      "width": "100%",
      "display": "flex",
      "flex-direction": "column",
      "gap": "12px",
      "margin-top": "30px"
    }
  }, [_c('c-flex', {
    attrs: {
      "justify-content": "space-between",
      "align-items": "center",
      "background-color": "neutral.white",
      "gap": "16px"
    }
  }, [_c('c-box', {
    attrs: {
      "flex-grow": "1",
      "width": "100%"
    }
  }, [_c('c-form-control', {
    attrs: {
      "pos": "relative",
      "w": "100%",
      "height": "100%"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic-search.svg'),
      "alt": "icon",
      "position": "absolute",
      "top": "50%",
      "transform": "translateY(-50%)",
      "left": "15px",
      "height": "24px",
      "z-index": "2"
    }
  }), _c('c-input', {
    attrs: {
      "id": "search",
      "type": "text",
      "w": "100%",
      "height": "100%",
      "min-height": "50px",
      "placeholder": "Search",
      "border-radius": "16px",
      "padding-left": "50px",
      "position": "relative",
      "z-index": "1",
      "border": "1px solid #C4C4C4"
    },
    model: {
      value: _vm.filterSearchClient,
      callback: function callback($$v) {
        _vm.filterSearchClient = $$v;
      },
      expression: "filterSearchClient"
    }
  })], 1)], 1), _c('c-flex', {
    attrs: {
      "flex-shrink": "0",
      "gap": "16px"
    }
  }, [_c('c-flex', {
    attrs: {
      "align": "center"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "14px-2",
      "size-desktop": "16px"
    }
  }, [_vm._v(" Show ")]), _c('c-select', {
    attrs: {
      "min-width": "140px",
      "border-radius": "8px",
      "size": "md",
      "color": "primary.400",
      "font-size": "18px",
      "font-weight": "500",
      "margin-left": "16px",
      "value": String((_vm$clients = _vm.clients) === null || _vm$clients === void 0 ? void 0 : (_vm$clients$meta = _vm$clients.meta) === null || _vm$clients$meta === void 0 ? void 0 : _vm$clients$meta.pagesize)
    },
    on: {
      "change": function change(perPage) {
        return _vm.onChangePerPage('CLIENTS', +perPage);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "5"
    }
  }, [_vm._v(" 5 Rows ")]), _c('option', {
    attrs: {
      "value": "10"
    }
  }, [_vm._v(" 10 Rows ")]), _c('option', {
    attrs: {
      "value": "30"
    }
  }, [_vm._v(" 30 Rows ")]), _c('option', {
    attrs: {
      "value": "50"
    }
  }, [_vm._v(" 50 Rows ")])])], 1), _c('ManagementClientFilterBtn', {
    attrs: {
      "filters": _vm.filters
    },
    on: {
      "change": function change(status) {
        return _vm.onChangeStatus('CLIENTS', status);
      }
    }
  })], 1)], 1), _c('BaseTable', {
    attrs: {
      "color": "primary",
      "columns": _vm.clients.columns,
      "items": _vm.clients.items,
      "page": (_vm$clients$meta2 = _vm.clients.meta) === null || _vm$clients$meta2 === void 0 ? void 0 : _vm$clients$meta2.page,
      "per-page": (_vm$clients$meta3 = _vm.clients.meta) === null || _vm$clients$meta3 === void 0 ? void 0 : _vm$clients$meta3.pagesize,
      "total-page": _vm.calculatePerPage((_vm$clients2 = _vm.clients) === null || _vm$clients2 === void 0 ? void 0 : _vm$clients2.total, (_vm$clients$meta4 = _vm.clients.meta) === null || _vm$clients$meta4 === void 0 ? void 0 : _vm$clients$meta4.pagesize),
      "sort": (_vm$clients$meta5 = _vm.clients.meta) === null || _vm$clients$meta5 === void 0 ? void 0 : _vm$clients$meta5.sort,
      "is-loading": _vm.isLoading
    },
    on: {
      "on-change-page": function onChangePage(page_) {
        return _vm.onChangePage('CLIENTS', page_);
      },
      "on-change-sort": function onChangeSort(sort) {
        return _vm.onChangeSort('CLIENTS', sort);
      }
    },
    scopedSlots: _vm._u([{
      key: "program",
      fn: function fn(_ref5) {
        var _item$programs, _item$programs2, _item$programs3;

        var item = _ref5.item;
        return [item !== null && item !== void 0 && (_item$programs = item.programs) !== null && _item$programs !== void 0 && _item$programs.name ? _c('c-box', [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$programs2 = item.programs) === null || _item$programs2 === void 0 ? void 0 : _item$programs2.name) + " -"), _c('br'), _vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$programs3 = item.programs) === null || _item$programs3 === void 0 ? void 0 : _item$programs3.programService) + " ")]) : _c('c-box', [_vm._v(" - ")])];
      }
    }, {
      key: "status",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [item !== null && item !== void 0 && item.status ? _c('ChipStatusProgram', {
          attrs: {
            "status": item === null || item === void 0 ? void 0 : item.status,
            "screen": "mobile-only"
          }
        }) : _c('c-box', [_vm._v(" - ")])];
      }
    }, {
      key: "aksi",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('c-button', {
          attrs: {
            "background-color": "white",
            "border-radius": "16px",
            "padding": "8px 16px",
            "font-size": "14px",
            "border": "2px solid #008C81",
            "font-weight": "medium",
            "color": "primary.400"
          },
          on: {
            "click": function click($event) {
              return _vm.toDetailClient(item);
            }
          }
        }, [_vm._v(" Lihat ")])];
      }
    }])
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }